.navbar-container {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}

.nav {
  display: flex;
  align-items: center;
  padding: 10px 1rem;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

.logo {
  height: 50px;
}

.links-container ul {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.links-container ul .navLink:hover {
  text-decoration: underline;
}

.Avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.7rem;
}

.links-header {
  display: none;
}

@media screen and (max-width: 1000px) {
  .links-container {
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: red; */
    height: 100vh;
    width: 100%;
    z-index: 4;
    padding: 1rem;
    background-color: white;
    transform: translateX(-100%);
    transition: all 0.4s;
  }

  .links-container.navbar-active {
    transform: translateX(0%) !important;
    transition: all 0.4s;
  }

  .links-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .links-container ul {
    margin-top: 3rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.7rem;
  }
  .links-container ul > * {
    width: 100%;
  }

  .links-container .navLink {
    padding: 10px;
    text-decoration: none;
    border-radius: 8px;
  }

  .links-container ul .navLink:hover {
    text-decoration: none;
  }
}
