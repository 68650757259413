.travelers {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.travelers-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px;
  padding-top: 60px;
  width: 90vw;
  max-width: 500px;
  background-color: white;
  border-radius: 15px;
}

.travelers-inner .close-btn {
  background-color: #53cbf0;
  color: #f8f8f8;
  border-color: #53cbf0;
  border-width: 1px 1px 3px;
  border-radius: 4px;
  padding: 10px 20px;
  position: absolute;
  top: 16px;
  right: 16px;
}
