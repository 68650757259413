@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

*::-webkit-scrollbar-thumb {
  background: #333741;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}
