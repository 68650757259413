.daypicker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.daypicker-inner {
  position: relative;
  padding: 24px;
  width: 95vw;
  max-width: 750px;
  background-color: white;
  max-height: 90vh;
  overflow-y: scroll;
  border-radius: 15px;
}

.daypicker-inner .close-btn {
  background-color: #53cbf0;
  color: #f8f8f8;
  border-color: #53cbf0;
  border-width: 1px 1px 3px;
  border-radius: 4px;
  padding: 10px 20px;
}
