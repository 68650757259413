nav:not(.nav) {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  z-index: 7;
}

div.dashboard-nav {
  display: flex;
  align-items: center;
  padding: 10px 1rem;
  margin: 0 auto;
  justify-content: space-between;
}

.logo {
  height: 50px;
}

.dashboard-links-container ul {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.dashboard-links-container ul .dashboard-navLink:hover {
  text-decoration: underline;
}

.Avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.7rem;
}

.links-header {
  display: none;
}

@media screen and (max-width: 1000px) {
  .dashboard-links-container {
    display: none;
  }

  .links-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu-icon {
    display: block;
  }
}

@media screen and (max-width: 1300px) {
  div.dashboard-nav {
    max-width: 1200px;
  }
}
