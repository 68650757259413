.chart-container {
  width: "100%";
  max-width: 300px;
  height: "250px";
  background-color: "red";
}

.chart-container * {
  color: white !important;
}

.chart-container rect {
  fill: transparent;
  width: 200px !important;
}
