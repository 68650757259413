.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("./assets/images/home1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.admin-dashboard-banner {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("./assets/images/admin-dashboard-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.wallet-banner {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("./assets/images/admin-dashboard-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
